<template>
    <b-card-actions
      action-collapse
      title="Daftar Kategori Aset"
    >
      <!-- ref="refreshCard"
      action-refresh
      @refresh="refreshStop('refreshCard')" -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button
          v-if="allowCreate()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click.prevent="add"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
          <b-modal
            v-if="(allowCreate() || allowUpdate())"
            id="modal-kategori"
            centered
            title="Form Kategori Aset"
          >
            <b-form>
              <b-form-group>
                <label for="kategori">Nama Kategori</label>
                <b-form-input v-model="kategori" id="kategori" placeholder="Nama kategori" />
              </b-form-group>
              <b-form-group>
                <label for="umur">Umur Aset (Tahun)</label>
                <b-input-group
                  >
                <b-form-input v-model="umur" id="umur" type="number" />
                <b-input-group-append>
          <b-button variant="outline-primary">
            Tahun
          </b-button>
        </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
  
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                  Simpan
                </b-button>
              </section>
            </template>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ (row.index + 1) }}
              </strong>
            </template>
            <template #cell(actions)="row">
              <b-button
              v-if="allowUpdate()"
              v-b-tooltip.hover.right="'Ubah'"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
              <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button
              v-if="allowDelete()"
              v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
              <feather-icon icon="TrashIcon"/>
              </b-button>
            </template>
  
            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
          <pre>{{ infoModal.content }}</pre>
        </b-modal>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </template>
  
  <script>
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,VBTooltip,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BCardActions,VBTooltip,ToastificationContent,
      BModal,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        showModalTambah: false,
        id: null,
          kategori: null,
          umur: null,
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "kategori", label: "kategori", sortable: true },
          { key: "umur", label: "umur (Tahun)", sortable: true },
          { key: "actions", label: "Aksi" },
        ],
        items: [],
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    mounted() {
      // Set the initial number of items
      
      this.getData()
  
    },
    methods: {
      add() {
        this.resetForm()
        this.$bvModal.show('modal-kategori')
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data kategori "${item.kategori}" ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('kategori-asset/save', [item])
            .then(() => {
                this.displaySuccess({
                    message: 'Kategori Aset berhasil dihapus!'
                })
                this.resetForm()
                this.getData()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        this.kategori = null
      },
      submit() {
        if(this.kategori == null || this.kategori == '') {
          this.displayError({
            message: 'Harap isi nama kategori!'
          })
          return false
        }
        let payload = {
          kategori: this.kategori,
          umur: this.umur
        }
  
        if(this.id) {
          payload.id = this.id
        }
  
        this.$store.dispatch('kategori-asset/save', [payload])
        .then(() => {
            this.$bvModal.hide('modal-kategori')
            this.resetForm()
            this.displaySuccess({
                message: 'Kategori Aset berhasil disimpan'
            })
            this.getData()
        })
        .catch(e => console.error(e))
      },
      edit(item) {
        let { id, kategori, umur } = item
        this.id = id
        this.kategori = kategori
        this.umur = umur
        this.$bvModal.show('modal-kategori')
      },
      getData() {
        this.$store.dispatch('kategori-asset/getData', {})
        .then(kategori => {
          this.items = kategori
          this.totalRows = this.items.length;
        })
        .catch(e => console.error(e))
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
  </script>
  